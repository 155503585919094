/* 6.5. Infographics */
.acr-infographic-item {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
}
.acr-infographic-item > i {
    line-height: 1;
    display: block;
    color: #848486;
    margin-bottom: 30px;
    font-size: 60px;
}
.acr-infographic-item h4 {
    color: #848486;
    margin-bottom: 10px;
}
.acr-infographic-item p {
    font-size: 18px;
    font-weight: 600;
    color: #848486;
    margin-bottom: 0;
}

/* Style 2 */
.infographics-2 .acr-infographic-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    transition: 0.3s;
}
.infographics-2 .acr-infographic-item:hover {
    box-shadow: 0 2px 9px 0px rgba(34, 12, 43, 0.1);
}
.infographics-2 .acr-infographic-item > i,
.infographics-3 .acr-infographic-item > i,
.infographics-4 .acr-infographic-item > i,
.infographics-5 .acr-infographic-item > i {
    color: #124f98;
}
.infographics-2 .acr-infographic-item h5 {
    margin-bottom: 10px;
}
.infographics-2 .acr-infographic-item p,
.infographics-3 .acr-infographic-item p,
.infographics-4 .acr-infographic-item p,
.infographics-5 .acr-infographic-item p {
    color: #515151;
    font-size: 14px;
    font-weight: 400;
}
.infographics-2 .acr-infographic-item a {
    display: inline-flex;
    margin-top: 30px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #124f98;
    border-radius: 50%;
    border: 1px solid #124f98;
    font-size: 15px;
}
.infographics-2 .acr-infographic-item a i {
    transition: 0.3s;
}
.infographics-2 .acr-infographic-item span,
.infographics-3 .acr-infographic-item span {
    opacity: 0;
    visibility: hidden;
    font-size: 0;
    font-weight: 600;
    transition: 0.1s;
}
.infographics-2 .acr-infographic-item:hover a {
    color: #fff;
    background-color: #124f98;
    width: 150px;
    border-radius: 8px;
}
.infographics-2 .acr-infographic-item:hover a:hover {
    background-color: #00a0a1;
    border-color: #00a0a1;
}
.infographics-2 .acr-infographic-item:hover a i {
    opacity: 0;
    visibility: hidden;
    display: none;
}
.infographics-2 .acr-infographic-item:hover a span {
    opacity: 1;
    visibility: visible;
    font-size: 14px;
}
/* Style 3 */
.infographics-3 .acr-infographic-item {
    display: flex;
    border-radius: 8px;
    transition: 0.3s;
    text-align: left;
}
.infographics-3 .acr-infographic-item i,
.infographics-4 .acr-infographic-item i,
.infographics-5 .acr-infographic-item {
    margin-right: 20px;
    font-size: 40px;
}
.infographics-3 .acr-infographic-item p,
.infographics-4 .acr-infographic-item p,
.infographics-5 .acr-infographic-item p {
    margin-bottom: 20px;
}
.infographics-3 .acr-infographic-item h5,
.infographics-4 .acr-infographic-item h5,
.infographics-5 .acr-infographic-item h5 {
    margin-bottom: 5px;
}

/* Style 4 */
.infographics-4 .acr-infographic-item {
    text-align: left;
}
.infographics-4 .acr-infographic-item i,
.infographics-5 .acr-infographic-item i {
    margin-right: 0;
    margin-bottom: 20px;
}
/* Style 5 */
.infographics-5 .acr-infographic-item {
    text-align: center;
    margin-right: 0;
    border-radius: 8px;
    background-color: #f7f9fc;
    padding: 20px;
}
